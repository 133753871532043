import * as React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export default function BasicGrid() {

  const StyledGird = props => {
    return (
      <Grid item xs={props.xs}>
        <Typography variant="body2" component="div"
          sx={{
            py: 2,
            textAlign: "center",
            fontSize: {
              xs: 14,
              sm: 16,
              md: 18,
            }
          }}
        >
          {props.column}
        </Typography>
      </Grid>
    )
  }


  return (
    
    <Grid item xs={12} >
      <Grid container spacing={.5}>

        <StyledGird xs={4} column="科目名"/>

        
        <StyledGird xs={3} column="担当教員"/>

        
        {/* <StyledGird xs={2} />
         
         */}
        <StyledGird xs={2.5} column="総合評価"/>

        
        <StyledGird xs={2.5} column="単位所得"/>
        
      </Grid>
      
    </Grid>
  )
}
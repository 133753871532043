import React, {useCallback, useEffect, useState} from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useQuery, createParam } from "function/common"
import { navigate } from 'gatsby';
import { getParams } from './keys'
import { useLocation } from "@reach/router"

export default function PaginationButtons({
  total, page
}) {
  const location = useLocation();
  const query = useQuery();
  const queries = getParams(query);

  
  const handleChange = (e, toPage)=> {
    navigate(`${location.search}`, {
      state: {
        ...queries,
        page: toPage,
      }
    })
  }

  const theme = useTheme();
  const isOverSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isOverXs = useMediaQuery(theme.breakpoints.up('xs'));
  const siblingCount  = isOverSm ? 1 : 0;
  const boundaryCount = isOverXs ? 1 : 0;

  return (
  <>
    <Stack spacing={2}>
      <Pagination 
        count={Math.ceil(total/30)} 
        // sx={{width:}}
        size="large" 
        shape="rounded" color="secondary"
        siblingCount = {siblingCount}
        boundaryCount= {boundaryCount}
        // hidePrevButton = {hidePrevButton}
        // hideNextButton
        onChange={handleChange}
        defaultPage={Number(page)}
        sx={{position: 'relative'}}
      />
    </Stack>
  </>
  );
}
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import MuiLink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { Link } from 'gatsby';
import { useInView } from "react-intersection-observer"; // 1.9K gzipped


const getColor = percent => {
  switch (true) {
    case percent < 30:
      return 'rgba(255,0,0,0.7)';
    case percent < 70:
      return 'rgba(255,255,0,0.7)';
    default:
      return 'rgba(50,50,255,0.8)';
  }
}
const Span = styled('span')(({ theme }) => ({
}));

const Teacher = (props) => {

  const style = {
    fontSize: 12
  }

  return(
    <TeacherBg elevation={0}>
      <div style={style}>
        {props.teacher}
      </div>
    </TeacherBg>
  )
}

const TeacherBg = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  height: theme.spacing(8),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default
}));

const StyledLink = styled(MuiLink)(({ theme }) => ({
}));

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  height: theme.spacing(8),
  textAlign: 'center',
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.text.primary,
}));

const Name = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  height: theme.spacing(8),
  textAlign: 'center',
  borderRadius: "8px",
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.mybg.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));


export const Intem = (props) => {

  const [inputValue, setInputValue] = useState("");
  const [ref, inView, entry] = useInView({ threshold: -20 });
  const [isLoading, setIsLoading] = React.useState(false);
  const [anime, setAnime] = useState(false)



  useEffect(() => {
    if (inView) {
      setAnime(true);
    }
  }, [inView]);


  React.useEffect(() => {
    setIsLoading(true);
  }, [])

  let   percent = props.percent;
  if (percent !== null && percent !== undefined)  {
    percent = Number(percent);
    percent = percent.toFixed(1);
  }

  const color = getColor(percent)
  const sx    = {};

  sx.root = {
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: '12px',
    boxShadow: 1,
    bgcolor: "transparent",
    fontWeight: 'bold',
    position: "relative",
    zIndex: 10,
    ...props.sx,
  }

  sx.bgColor = {
    bgcolor: color,
    height: "calc(100% * "+percent/100+")",
    position: "absolute",
    width: "100%",
    left: 0,
    bottom: 0,
    zIndex: 25,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    p: 0,
  }

  sx.back = {
    height: "100%",
    width: "100%",
    position: "absolute",
    bgcolor: "rgba(100,100,100,0.15)",
    left: 0,
    bottom: 0,
    zIndex: 20,
    p: 0,
  }

  sx.text = {
    alignItems: 'center',
    overflow: 'hidden',
    width: "100%",
    bgcolor: "transparent",
    left: "50%",
    top: "50%",
    transform: "translateY(-50%) translateX(-50%)",
    fontWeight: 'bold',
    position: "absolute",
    // color: "rgba(255,255,255,0.9)",
    zIndex: 30,
    fontSize: 15,
  }
  

  return (
    <>
      { percent === null || percent === undefined ? 

          <NullItem /> 
        :

          <Item sx={sx.root} square>
            {"　"}

            <Item sx={sx.bgColor} className={ isLoading ? "load-box" : ""} />

            <Item sx={sx.back} />
            <Span sx={sx.text}>
              {percent}<span style={{fontSize: 8}}>点</span>
            </Span>
          </Item>

      }

    </>
  )
}

const NullItem = () => {

  const sx = {};

  sx.root = {
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: '12px',
    boxShadow: 1,
    bgcolor: "transparent",
    fontWeight: 'bold',
    position: "relative",
    zIndex: 10,
    boxShadow: "transparent",
  }


  sx.text = {
    alignItems: 'center',
    overflow: 'hidden',
    width: "100%",
    bgcolor: "transparent",
    left: "50%",
    top: "50%",
    transform: "translateY(-50%) translateX(-50%)",
    position: "absolute",
    zIndex: 30,
    fontSize: 12,
    color: "#ccc"
  }

  return (
    <>
      <Item sx={sx.root} elevation={0}>
        {"　"}
        <Span sx={sx.text}>
          NoData
        </Span>
      </Item>
    </>
  )
  
}

export default function BasicGrid({data}) {

  return (
    <Grid item xs={12} >

      <Grid container spacing={.5}>
        <Grid item xs={4}>
          <Name>
          <Link to={"/info/"+data.id+":"+data.year}>
            <StyledLink underline="always" color="#0072E5" component="span">
              {data.sub_name}
            </StyledLink>
          </Link>
          </Name>
        </Grid>

        <Grid item xs={3}>
          <Teacher teacher={data.担当教員} />
        </Grid>
        <Grid item xs={2.5}>
          <Intem percent={data.sgo}/>
        </Grid>
        {/* <Grid item xs={2.5}>
          <Intem percent={data.con}/>
        </Grid> */}
        <Grid item xs={2.5}>
          <Intem percent={data.tan}/>
        </Grid>
      </Grid>
      
    </Grid>
  );
}
import React from 'react';
import { Box as BoxCard, BoxColumn, Pagination, SearchOption, NotFound } from './';
import Grid from '@mui/material/Grid';



const List = ({data}) => {

  return(
  <Grid container rowSpacing={1.5}   
    justifyContent="center"
    alignItems="center"
  >

    {data && data.map( (values, index)  => {
      return(
        <Grid item xs={11} key={index} className={""}>
        <Grid container rowSpacing={1.5} >

          {(index % 10 === 0) && (
            <Grid item xs={12}>
              <BoxColumn />
            </Grid>
          )}

          <Grid item xs={12}>
            <BoxCard data={values} />
          </Grid>

        </Grid>
        </Grid>
      )
    })}

  </Grid>
  )
}


export default function ({
  from, to , total, data, page
}) {
  
  return(
    <>
       
      <Grid item xs={12} sx={{maxWidth: "1000px"}}>
        <SearchOption 
          from={from} to={to} total={total}
        />
      </Grid >

      {
        !(to < 1 || from > total) ?

        <>
          <Grid item xs={12} sx={{maxWidth: "1000px"}}>
            <List data={data} />
          </Grid >

          <Grid item 
            sx={{
              paddingTop: 2,
              display: "flex",
              justifyContent:"center",
              alignItems:"center"
            }}
            xs={12}
          >
            <Pagination 
              total={total}
              page={page}
            />
          </Grid >
        </> :
        <NotFound />
         
      }

   
    </>
  )
}


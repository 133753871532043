import React ,{ useState, useCallBack, useEffect } from 'react';
import { Box as BoxCard, BoxColumn, Pagination} from './';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const ITEM_HEIGHT = 48 * 1.5;
export default function (props) {

  let keys = {};

  const [value, setValue] = React.useState(props.value[props.code]);
  useEffect(() => {
    setValue(props.value[props.code]);
  }, [props.value]);

  
  if (Array.isArray(props.keys)) {
    props.keys.forEach(val => {
      keys[val] = val;
    })
  } else {
    keys = props.keys;
  }

  let keysKey = Object.keys(keys);
  if (props.reverse) {
    const newArray = [];
    keysKey = keysKey.reverse().forEach((key) => {
      newArray.push(key);
    });
    keysKey = newArray;
  }

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    props.onChange(value, props.code);
  }, [value])


  return (
    <Grid container sx={{mt: 2}}>

      <Grid item xs={4} className='MyTest' sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography variant='subtitle1' sx={{pb: 1}} >
          {props.title}
        </Typography>
      </Grid>

      <Grid item xs={8}>
      <FormControl fullWidth sx={{
        mt: .5
      }} >
        <Select
          value={value}
          onChange={handleChange}
          fullWidth
          displayEmpty
          MenuProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
            },
          }}
          sx={{ 
            "& legend": {
              display: "none",
            },
            py: 0,
            height: 24,
          }}
        >
          {
            keysKey.map((key, index) => {
              const val = index === 0 ? "" : key;
              return (
                <MenuItem value={val} key={index} >
                  {keys[key]}
                </MenuItem>
              );
            })
          }

        </Select>
      </FormControl>
      </Grid>

    </Grid>
  )
}

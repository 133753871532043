import React from "react";
import { motion } from "framer-motion";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Typography } from "@mui/material";


const List = props => {

}

export default function (props) {
  return (
    <>
      <Box>

        <Typography textAlign={"center"} variant="h6" fontWeight={600}>
          お探しのデータは見つかりませんでした
        </Typography>

        <Box
          sx={theme => ({
            background: `linear-gradient(
              30deg, #efe8f9, #fefefe), linear-gradient(
              200deg, #efe8f9, #fefefe), linear-gradient( #fefefe, #f3ebff)`,
            border: "1px solid #cccccc78", borderRadius: 4,
            py: 3, px: 5,
            my: 5, mx: "auto",
            width: ["85vw" , 450],
            boxShadow: theme.shadows[6],
            "& p, h6": {
              color: "rgba(0,0,0,0.7)"
            }  
          })}
        >

          <Typography textAlign={"center"} variant="h6" pb={3} >
            検索のヒント
          </Typography>


          <p>
            <CheckIcon size="small" sx={{pr: 1}} />
            2020年度以降の科目
          </p>

          <p>
            <CheckIcon size="small" sx={{pr: 1}} />
            検索オプションより 絞り込み・並び替え
          </p>

          <p>
            <CheckIcon size="small" sx={{pr: 1}} />
            空白区切りで複数条件が可能
          </p>
          
          <p>
            <CheckIcon size="small" sx={{pr: 1}} />
            空文字で全検索
          </p>



        </Box>
        
      </Box>
    </>
  )
}
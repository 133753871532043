import React ,{ useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { LoadingBox } from 'components/UIkit';
import { List } from './';
import { useQuery } from 'function/common';
import {fetch } from "./keys"
import { useLocation } from "@reach/router"

export default function Main () {


  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [queryValue, setQueryValue] = useState("")
  const query = useQuery();
  const location = useLocation();
  const queries = location.state;


  useEffect(() => {
    setIsLoading(true);
    let fetchQuery = {};
    if (query.get("query")) {

      if (queryValue == query.get("query")) {
        // page や option 切り替え
        fetchQuery = {
          ...queries,
          query: query.get("query"),
        }
      } else {

        // 更新 1 ページ目を表示
        fetchQuery = {
          ...queries,
          query: query.get("query"),
          page: 1,
        }
      }
      
      
      

      setQueryValue(query.get("query"));
    } else {
      fetchQuery = {...queries}
    }


    typeof window !== "undefined" && typeof window.gtag  !== "undefined" &&
      window.gtag("event", 'search_lessons', {
        search_term: fetchQuery.query,
        ...fetchQuery,
      })


    fetch(fetchQuery).then(res => {
      console.log(res)
      setData(res)

    }).catch(err => {
      setIsError(true);

    }).finally(() => {
      setIsLoading(false);
    })

  }, [queries])
  

    return(
    <>
      <Grid container rowSpacing={1.5}   
        justifyContent="center"
        alignItems="center"
      >
         
      {
        isLoading ?

        <LoadingBox />

        :

        <List {...data} />

      }

      </Grid>
    </>
  )
}


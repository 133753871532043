import React ,{ useState, useCallBack, useEffect } from 'react';
import { FormSelections} from './';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { SearchInput } from 'components/UIkit';
import { getKeys } from "./keys"
import { useQuery, createParam } from 'function/common';

import Button from '@mui/material/Button';
import SettingsIcon from '@mui/icons-material/Settings';
import RotateLeftIcon from "@mui/icons-material/RotateLeft"

import { initialKeys } from './keys';
import MuiBadge from '@mui/material/Badge';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { styled } from '@mui/material/styles';
import { useCookies } from "react-cookie";

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import { AccordionSummary } from '@mui/material';

const Badge = styled(MuiBadge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 3,
    top: 5,
    padding: '0',
    backgroundColor: theme.palette.background.default,
    zIndex: 50
  },
}));

delete initialKeys["query"]

export default function (props) {
  const [cookies , setCookie] = useCookies();
  const [placeKey, setPlaceKey] = useState([])
  const location = useLocation();
  const params = location.state;
  const query = useQuery();
  const initialCookies = cookies["serach_options"];
  if (initialCookies) delete initialCookies["query"];
  
  let initialOption =  {
    ...initialKeys,
    ...initialCookies,
    ...params,
  }
  if (query.get("query")) {
    initialOption["query"] = query.get("query");
  }

  console.log(initialOption);

  let hasOptions = false;
  Object.keys(initialKeys).forEach(key => {
    
    if (key != 'page' && initialKeys[key] !==  initialOption[key]) {
      hasOptions = true;
    }
    
  })


  const [optionValue, setOptionValue] = useState(initialOption);
  const [inputValue, setInputValue] = useState(initialOption["query"] ?? "")

  const handleValue = (value, key) => {
    setOptionValue(prevs => ({
      ...prevs,
      [key]: value,
    }));
  }

  useEffect(() => {
    getKeys().then(res => setPlaceKey(["全て", ...res]))
  }, [])
  


  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    console.log(optionValue);
  }, [optionValue])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    console.log(inputValue);
    setCookie("serach_options", optionValue);
    let queries = {};
    Object.keys(optionValue).forEach(key => {
      if ( optionValue[key] !== initialKeys[key]) {
        queries[key] = optionValue[key];
      }
    });
    queries["query"] = inputValue.trim()
    const param = queries["query"] == "" ? "" : `?${createParam({query: queries["query"]})}`
    navigate(param, {
      state: queries,
    })
    
    handleClose();
    return false;
  }

  const handleReset = () => {
    setOptionValue(initialKeys);
  }

  const handleChange = (e)  => {
    setOpen(prev => !prev);
  };

  const handleInputValue = e => {
    setInputValue(e.target.value);
    
  }

  

  return (
    <div>
      <Grid container>

        <Grid item xs={12}>

          <SearchInput
            onSubmit={handleSubmit}
            onChange={handleInputValue}
            value={inputValue}
          />
          

        </Grid>

        <Grid item xs={12} >
          <Box sx={{px: 3, my: 2 , display: "flex"}} >

            <Box 
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              {
                !(props.to < 1 || props.from > props.total) &&

                <Typography variant='subtitle2' >
                  全{props.total}件中 {props.from} - {props.to}件
                </Typography>
              }

            </Box>


            <Box flexGrow={1} />

            <Button variant="outlined" onClick={handleChange} 
              endIcon={
                <Badge
                  invisible={!hasOptions}
                  overlap="circular" 
                  color="secondary"
                  badgeContent={<CheckCircleOutlineIcon color="warning" sx={{fontSize: 18, zIndex: 15}} />}  >
                  <SettingsIcon sx={{ zIndex: 10}}/>
                </Badge>
              }
            >
              検索オプション
            </Button>
          </Box>

        </Grid>



      </Grid>

      <Box >
        <Accordion expanded={open} onChange={handleChange}>
          <AccordionSummary className='d-none' />
          <AccordionDetails sx={{my: 2}}>
          <Box sx={{ my: 1, width: "100%" }} >

          <Box display={"flex"}>
            <Box flexGrow={1}/>
            <Button onClick={handleReset} startIcon={<RotateLeftIcon />}>
              リセット
            </Button>
          </Box>


          <FormSelections 
            title="並び替え" code="order"
            keys={{sgo :"総合評価の高い順", tan: "単位取得しやすい順" }} 
            onChange={handleValue} value={optionValue}
          />

          <FormSelections 
            title="開講部局" keys={placeKey} value={optionValue}
            code="place" onChange={handleValue} 
          />

          <FormSelections 
            title="開講期" keys={["指定しない", "前期", "後期", "通年"]} 
            code="term" onChange={handleValue} value={optionValue}
          />

          <FormSelections 
            title="開講年度" 
            keys={["指定しない", "2022", "2021", "2020" ]} 
            reverse code="year" value={optionValue}
            onChange={handleValue} 
          />


            {/* <FormHelperText>Without label</FormHelperText> */}
          </Box>

          <Box sx={{justifyContent: "center", display: "flex"}}>
            <Button onClick={handleClose} variant="outlined" sx={{width: "7.5rem", mr: [".5rem", "2rem"] }} color='secondary'>
              キャンセル
            </Button>
            <Button onClick={handleSubmit} variant="contained" autoFocus sx={{width: "7.5rem",  ml: [".5rem", "2rem"] }} color='secondary'>
              適応
            </Button>
          </Box>
          </AccordionDetails>
        </Accordion>
      </Box>

    </div>
  );
}

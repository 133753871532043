import axios from "axios";

export const getKeys = () => {

  return  new Promise(function(resolve, reject) {
    
    axios.post('https://api.rakutan-alps.com/api/lessons/keys/')
    .then(res => {
 
      console.log(res.data);
      resolve(Object.values(res.data));

    }).catch(err => {
      reject(err);
    });

  });;

}

export const initialKeys = {
  order :    "",
  place :    "",
  term  :    "",
  year  :    "2022",
  page  :    "1",
  query :    "",
}

export const optionKeys = [
  "order" ,
  "place" ,
  "term"  ,
  "year"  ,
  "page"  ,
  "query" ,
]


export const fetch = (queries) => {
  const fetchParams = {
    ...initialKeys,
    ...queries,
  }
  return  new Promise(function(resolve, reject) {
    
    axios.post('https://api.rakutan-alps.com/api/v2/lessons/search/', {
      ...fetchParams
    })
    .then(({data}) => {

      resolve(data);

    }).catch(err => {

      reject(err);

    });

  });;

}

export const getParams = (query) => {
  const queries = {};
  optionKeys.forEach(val => {
    const param = query.get(val);
    if (param !== null) 
      { queries[val] = param ;}
  })

  return queries ;
}
 